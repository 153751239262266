/*SLIDER BANNER*/
.staticBanner300,
.staticBanner,
.slideElement {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: block !important;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .slideElement {
    background-position: center right;
  }
}

.slideElement aside {
  position: absolute;
  color: #fff;
  z-index: 1;
  /* top: 50%;
	transform: translate(0%, -50%);
	-ms-transform: translate(0%, -50%);
	-webkit-transform: translate(0%, -50%); */
  width: 100%;
  bottom: 15%;
}

.slideElement aside strong.title,
.slideElement aside h1,
.slideElement aside p {
  text-shadow: 0 0 2px #1c1c1c;
  margin-bottom: 20px;
  font-family: "Light";
}

.staticBanner300 aside h1 {
  margin: 0;
}

.slideElement aside strong.title {
  display: block;
  font-size: 2.25em;
  line-height: 1.25em;
}

.staticBanner {
  padding-top: 6em;
  padding-bottom: 6em;
  color: var(--white);
}

.staticBanner .container {
  position: relative;
  z-index: 1;
}

.staticBanner .title,
.staticBanner p,
.staticBanner .heading {
  margin-bottom: 2em;
}

.staticBanner300 {
  height: 300px;
}

.slideH300 .slideElement {
  height: 300px;
  overflow: hidden;
}

.slideH400 .slideElement {
  height: 400px;
  overflow: hidden;
}

.slideH500 .slideElement {
  height: 450px;
  overflow: hidden;
}

@media screen and (min-width: 576px) and (max-width: 991px) {
  .modal-dialog {
    max-width: 90% !important;
  }
}

@media only screen and (max-width: 767px) {
  .staticBanner300 {
    height: 200px;
  }

  .slideH300 .slideElement {
    height: 200px;
  }

  .slideH400 .slideElement {
    height: 330px;
  }

  .slideH500 .slideElement {
    height: auto;
  }

  .imageTextBelow.staticBanner300,
  .imageTextBelow.staticBanner,
  .imageTextBelow .slideElement {
    background-image: none !important;
    height: auto;
    background: var(--secondary);
    padding: 0;
  }

  .imageTextBelow.staticBanner300 aside,
  .staticBanner .container,
  .imageTextBelow .slideElement aside {
    position: relative;
    transform: none;
    left: unset;
    top: unset;
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .imageTextBelow .slideElement aside {
    padding-bottom: 5em;
  }

  .staticBanner .container h1,
  .imageTextBelow.staticBanner300 aside h1,
  .slideElement aside strong.title,
  .imageTextBelow .slideElement aside h1 {
    font-size: 1.2em;
  }

  .imageTextBelow.staticBanner300 aside {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

/*common slick*/

.slick-dots {
  position: absolute;
  z-index: 0;
  width: 100%;
  bottom: 20px;
  left: 0;
  text-align: center;
  padding: 0;
  -webkit-appearance: none;
}

.slick-dots li {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
  position: relative;
  width: 10px;
  height: 10px;
  -webkit-appearance: none;
  overflow: hidden;
}

.slick-dots li button {
  display: block;
  width: 10px;
  height: 10px;
  text-indent: -9999px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.5);
  -webkit-appearance: none;
  border: none;
}

.slick-dots li.slick-active button,
.slick-dots li.slick-active button:hover,
.slick-dots li button:hover {
  background: var(--white) !important;
  position: relative;
  -webkit-appearance: none;
}

.btnNext,
.btnPrev,
.btnNext:active,
.btnPrev:active,
.btnNext:focus,
.btnPrev:focus {
  width: 35px;
  height: 35px;
  right: 20px;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  -webkit-transform: translate(0%, -50%);
  color: var(--primary);
  z-index: 1;
  text-align: center;
  background: var(--light-gray);
  border-radius: 50%;
  -webkit-appearance: none;
}

.arrowOutside .btnNext,
.arrowOutside .btnPrev{
  background:var(--tertiary) !important;
  color: var(--white);
}

.arrowOutside .btnNext:hover,
.arrowOutside .btnPrev:hover{
  background:var(--primary) !important;
  color: var(--white);
}

.btnNext svg,
.btnPrev svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btnPrev {
  left: 20px;
}

.arrowOutside .btnPrev {
  left: -10px;
}

.arrowOutside .btnNext {
  right: -10px;
}

@media only screen and (min-width: 1300px) {
  .arrowOutside .btnPrev {
    left: -60px;
  }

  .arrowOutside .btnNext {
    right: -60px;
  }
}

.btnPrev.slick-disabled,
.btnNext.slick-disabled {
  opacity: 0.1;
  cursor: not-allowed;
}

.slideShow,
.thumbnailTabsContent,
.thumbnailTabs {
  opacity: 0;
}

.withSpaceSlider .slick-list {
  margin: 0 -12px !important;
}

.withSpaceSlider .slick-slide > div {
  padding: 0 12px !important;
}

.slick-slider.slick-initialized {
  opacity: 1;
  display: block;
}

.slick-slide{ height: inherit;}
.slick-slide > div{
  height:100%;
}

.slick-track {
  display: flex !important;
}

@media only screen and (max-width: 991px) {
  .arrowOutside .btnPrev {
    left: 0;
    background: rgba(255, 255, 255, 1);
  }

  .arrowOutside .btnNext {
    right: 0;
    background: rgba(255, 255, 255, 1);
  }
}

@media only screen and (max-width: 575px) {
  .arrowOutside .btnPrev {
    left: 0;
  }

  .arrowOutside .btnNext {
    right: 0;
  }
}

.btnNext:hover,
.btnPrev:hover {
  background: var(--white);
  color: var(--primary);
  cursor: pointer;
}

/*Slick*/

@media only screen and (min-width: 768px) {
  .col-lg-7 .slideElement aside {
    padding: 0 50px;
  }
}
