/*NEWS TICKER*/

.newsTickerItem {
    font-family: "Medium";
    font-size: 0.9em;
    color: var(--primary);
    min-height: 30px;
}
.newsTickerItem a{ color: inherit;}

@media (min-width: 768px) {
    .newsTickerItem p{
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.btn{
    box-shadow: none !important;
}

.newsTicker{
    position: relative;
}
.newsTicker .btnNext,
.newsTicker .btnPrev{
    color: var(--primary);
    background: none;
}

.newsTicker .btnNext,
.newsTicker .btnPrev{
    color: var(--primary);
    position: absolute;
    right: -70px;
    top: 50%;
    z-index: 1;
    padding: 0 10px;
    font-size: 1.5em;
    width: auto;
}

.newsTicker .btnPrev{
    right: -50px;
    left: unset;
}

@media (max-width: 991px) {
    .newsTicker .btnNext,
    .newsTicker .btnPrev{
        top:50%;
        transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
    }

    .newsTicker .btnNext{
        right: -10px;
    }

    .newsTicker .btnPrev{
        right: unset;
        left: -10px;
    }
}

@media (max-width: 767px) {
    .newsTickerItem {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }    
}
/*END OF NEWS TICKER*/
