.breadCrumbs {
  font-size: 0.9em;
  border-bottom: 1px solid var(--gray);
  color: var(--danger);
  font-family: "Medium";
  padding: .5rem 0;

}

.breadCrumbs li {
  position: relative;
  padding-right: 1em;
}

.breadCrumbs li:not(:last-child):after {
  content: "\f105";
  position: absolute;
  right: -2px;
  top: 1px;
  font-family: "Font Awesome 5 Free", "FontAwesome";
  color: var(--primary);
  text-transform: none;
}

.breadCrumbs li a {
  color: var(--primary);
}
.breadCrumbs li a:hover,
.breadCrumbs li a.active {
  color: var(--danger);
}