@font-face {
  font-family: 'uowd-icons';
  src: url('fonts/uowd-icons.eot?icons30');
  src: url('fonts/uowd-icons.eot?icons30#iefix') format('embedded-opentype'),
      url('fonts/uowd-icons.ttf?icons30') format('truetype'),
      url('fonts/uowd-icons.woff?icons30') format('woff'),
      url('fonts/uowd-icons.svg?icons30#uowd-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="fac-"],
[class*=" fac-"] {
  /* use !important to prevent issues with browser extensions that change fonts 
  font-family: 'uowd-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;*/
  /* Better Font Rendering ===========
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */



  display: inline-block;
  font-family: 'uowd-icons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fac-booker:before {
  content: "\e91b";
}
.fac-security:before {
  content: "\e91c";
}
.fac-box:before {
  content: "\e91d";
}
.fac-teaching:before {
  content: "\e909";
}
.fac-pdf:before {
  content: "\e915";
}
.fac-scholar:before {
  content: "\e916";
}
.fac-src:before {
  content: "\e917";
}
.fac-success:before {
  content: "\e918";
}
.fac-visa:before {
  content: "\e919";
}
.fac-transportation:before {
  content: "\e91a";
}
.fac-uowdx:before {
  content: "\e935";
}
.fac-arrow-right-circle:before {
  content: "\e936";
}
.fac-career-connect:before {
  content: "\e937";
}
.fac-diploma:before {
  content: "\e938";
}
.fac-moodle:before {
  content: "\e913";
}
.fac-outlook:before {
  content: "\e914";
}
.fac-antispam:before {
  content: "\e927";
}
.fac-intranet:before {
  content: "\e928";
}
.fac-webmail:before {
  content: "\e929";
}
.fac-desktop:before {
  content: "\e92a";
}
.fac-student-email:before {
  content: "\e92b";
}
.fac-feedback:before {
  content: "\e92c";
}
.fac-finance:before {
  content: "\e934";
}
.fac-arrow-down-circle:before {
  content: "\e900";
}
.fac-hr:before {
  content: "\e901";
}
.fac-headset:before {
  content: "\e902";
}
.fac-info:before {
  content: "\e903";
}
.fac-library:before {
  content: "\e904";
}
.fac-sign-out:before {
  content: "\e905";
}
.fac-bell:before {
  content: "\e906";
}
.fac-registrar:before {
  content: "\e907";
}
.fac-search:before {
  content: "\e908";
}
.fac-sharepoint:before {
  content: "\e90a";
}
.fac-directory:before {
  content: "\e90b";
}
.fac-strategic-planning:before {
  content: "\e90c";
}
.fac-user:before {
  content: "\e90d";
}
.fac-book:before {
  content: "\e90e";
}
.fac-arrow-down:before {
  content: "\e90f";
}
.fac-branding:before {
  content: "\e910";
}
.fac-committee:before {
  content: "\e911";
}
.fac-document:before {
  content: "\e912";
}
