@font-face {
	font-family: "Medium";
	src: url("./fonts/Montserrat-Medium.eot");
	src: url("./fonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"),
		url("./fonts/Montserrat-Medium.woff2") format("woff2"),
		url("./fonts/Montserrat-Medium.woff") format("woff"),
		url("./fonts/Montserrat-Medium.ttf") format("truetype"),
		url("./fonts/Montserrat-Medium.svg#Montserrat-Medium") format("svg");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Bold";
	src: url("./fonts/Montserrat-Bold.eot");
	src: url("./fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"),
		url("./fonts/Montserrat-Bold.woff2") format("woff2"),
		url("./fonts/Montserrat-Bold.woff") format("woff"),
		url("./fonts/Montserrat-Bold.ttf") format("truetype"),
		url("./fonts/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Regular";
	src: url("./fonts/Montserrat-Regular.eot");
	src: url("./fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"),
		url("./fonts/Montserrat-Regular.woff2") format("woff2"),
		url("./fonts/Montserrat-Regular.woff") format("woff"),
		url("./fonts/Montserrat-Regular.ttf") format("truetype"),
		url("./fonts/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Italic";
	src: url("./fonts/Montserrat-Italic.eot");
	src: url("./fonts/Montserrat-Italic.eot?#iefix") format("embedded-opentype"),
		url("./fonts/Montserrat-Italic.woff2") format("woff2"),
		url("./fonts/Montserrat-Italic.woff") format("woff"),
		url("./fonts/Montserrat-Italic.ttf") format("truetype"),
		url("./fonts/Montserrat-Italic.svg#Montserrat-Italic") format("svg");
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Light";
	src: url("./fonts/Montserrat-Light.eot");
	src: url("./fonts/Montserrat-Light.eot?#iefix") format("embedded-opentype"),
		url("./fonts/Montserrat-Light.woff2") format("woff2"),
		url("./fonts/Montserrat-Light.woff") format("woff"),
		url("./fonts/Montserrat-Light.ttf") format("truetype"),
		url("./fonts/Montserrat-Light.svg#Montserrat-Light") format("svg");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "FontAwesome";
	font-style: normal;
	font-weight: 900;
	font-display: block;
	src: url("./fonts/fa-solid-900.eot");
	src: url("./fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"),
		url("./fonts/fa-solid-900.woff2") format("woff2"),
		url("./fonts/fa-solid-900.woff") format("woff"),
		url("./fonts/fa-solid-900.ttf") format("truetype"),
		url("./fonts/fa-solid-900.svg#fontawesome") format("svg");
}

:root {
	--primary: #001541;
	--secondary: #021f5b;
	--tertiary: #03477e;
	--quaternary: #04589c;
	--quinary: #019fd6;
	--senary: #32caff;
	--white: #ffffff;
	--gray: #dcdcdc;
	--light-gray: #eeeeee;
	--danger: #e72419;
	--rounded: 0.333em;
	--placeholder: #999999;
	--tertiary-hover: #046bbe;
	--quaternary-hover: #01b6f5;
	--danger-hover: #ff0000;
	--brown: #180303;
	--dark-gray: #666666;
	--dark: #02102f;
}

/* ---- preset style START ---- */

.opacity-90 {
	opacity: 0.9;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	color: var(--placeholder);
	opacity: 1;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
	color: var(--placeholder);
	opacity: 1;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
	color: var(--placeholder);
	opacity: 1;
}

.form-control::placeholder {
	color: var(--placeholder);
}

.form-control {
	border-radius: 0;
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	-webkit-appearance: none;
	font-size: 1em;
	font-family: "Regular";
}

.form-control-lg {
	height: 46px;
}

.form-group {
	margin-bottom: 1.5em;
}
.form-group label {
	font-family: "Medium";
	font-size: 0.925em;
	margin-bottom: 0.25em;
}

.form-group label.required {
	position: relative;
}

.form-group label.required:after {
	content: "*";
	position: absolute;
	padding-left: 3px;
	font-size: 1.5em;
	color: var(--danger);
}

html,
body {
	-webkit-text-size-adjust: none;
	margin: 0px;
	padding: 0px;
	height: 100%;
	width: 100%;
	max-width: 100%;
	min-height: 100%;
	-webkit-overflow-scrolling: touch;
	/* background-image:url('../images/courses-bg.jpg');
    background-position: center bottom;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: 100%; */
}

img {
	border: none;
	margin: 0;
	-webkit-user-select: none;
	-moz-user-select: -moz-none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a,
a:link,
a:visited,
a:focus,
input:focus,
textarea:focus {
	outline: none;
	text-decoration: none;
}

select:focus {
	outline: none;
}

textarea {
	resize: none;
}

form,
input,
select,
textarea,
p,
h1,
h2,
h3,
h4,
h5,
h6
/* ul,
ol */ {
	margin: 0;
	padding: 0;
}

input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

iframe {
	display: block;
}
p + p {
	margin-top: 1em;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
time {
	display: block;
	margin: 0;
	padding: 0;
}

::selection {
	background: #1c1c1c;
	color: #ffffff;
}

::-moz-selection {
	background: #1c1c1c;
	color: #ffffff;
}
/* 
ul {
  list-style: none;
}

li {
  padding: 0;
  margin: 0;
} */

label {
	font-weight: normal !important;
}

.fa-ul > li:not(:last-child) {
	margin-bottom: 15px;
}

.lead {
	font-size: 1.2em;
	line-height: 1.5em;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Medium";
	line-height: 1.25em;
}

.h1,
h1 {
	font-size: 2.5em;
}
.h2,
h2 {
	font-size: 2.25em;
}
.h3,
h3 {
	font-size: 1.5em;
}
.h4,
h4 {
	font-size: 1.25em;
}
.h5,
h5 {
	font-size: 1.154em;
}
.h6,
h6 {
	font-size: 1em;
}

@media only screen and (max-width: 991px) {
	.h1,
	h1 {
		font-size: 2.25em;
	}
	.h2,
	h2 {
		font-size: 2em;
	}
}

@media only screen and (max-width: 767px) {
	.h1,
	h1 {
		font-size: 2em;
	}
	.h2,
	h2 {
		font-size: 1.75em;
	}
	.h3,
	h3 {
		font-size: 1.5em;
	}
	.h4,
	h4 {
		font-size: 1.25em;
	}
	.h5,
	h5 {
		font-size: 1em;
	}
	.h6,
	h6 {
		font-size: 0.925em;
	}
}

@media only screen and (min-width: 1601px) {
	section > .minHeight {
		height: 250px;
	}
}

.content .h1,
.content .h2,
.content .h3,
.content .h4,
.content .h5,
.content .h6,
.content h1,
.content h2:not(.accordion-header),
.content h3,
.content h4,
.content h5,
.content h6 {
	margin-top: 40px;
	margin-bottom: 30px;
}

.underlined {
	padding-bottom: 0.75em;
	margin-bottom: 1em !important;
	text-align: center;
	text-transform: uppercase;
	position: relative;
}

.underlined:after {
	content: "";
	width: 150px;
	height: 5px;
	position: absolute;
	left: 50%;
	bottom: 0;
	transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	background-color: var(--danger);
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
	font-size: 0.8em;
	display: block;
	line-height: 1.5em;
	font-family: "Regular";
}

.table-responsive table thead {
	background: var(--quinary);
	color: #fff;
}

.table-striped tbody tr:hover,
.table-striped tbody tr {
	background-color: #d9e6ff;
}

.table-striped tbody tr:hover:nth-of-type(2n + 1),
.table-striped tbody tr:nth-of-type(2n + 1) {
	background-color: #b9ccf7;
}

table th,
table td {
	vertical-align: middle !important;
}

.content table {
	width: 100%;
}
.content table tbody tr:hover:nth-of-type(2n + 1),
.content table tbody tr:nth-of-type(2n + 1) {
	background-color: #b9ccf7;
}

.content table tbody tr,
.content table tbody tr:hover {
	background-color: #d9e6ff;
}

.content table td,
.content table th {
	padding: 0.5rem;
}

.content table thead tr {
	background: var(--tertiary);
	font-family: "Bold";
	color: var(--white);
}

.content table {
	border: 1px solid #dddddd;
}
.content table > :not(caption) > * > * {
	border-width: 0 1px;
	border-color: #dddddd;
}

.content table.plain td,
.content table.plain th,
table.plain tr,
table.plain th {
	background: transparent !important;
	border-width: 1px;
	border-color: #dddddd;
}
th,
b,
strong,
.bold {
	font-family: "Bold" !important;
}

em {
	font-family: "Italic" !important;
}

.regular {
	font-family: "Regular" !important;
}

.medium {
	font-family: "Medium" !important;
}

.light {
	font-family: "Light" !important;
}

p:last-child {
	margin-bottom: 0;
}

.stretched {
	letter-spacing: 2px;
}

.text-default:focus,
.text-default:active,
.text-default {
	color: var(--primary) !important;
}

.text-secondary:focus,
.text-secondary:active,
.text-secondary {
	color: var(--secondary) !important;
}

.text-tertiary:focus,
.text-tertiary:active,
.text-tertiary {
	color: var(--tertiary) !important;
}

.text-quaternary:focus,
.text-quaternary:active,
.text-quaternary {
	color: var(--quaternary) !important;
}

.text-quinary:focus,
.text-quinary:active,
.text-quinary {
	color: var(--quinary) !important;
}

.text-senary:focus,
.text-senary:active,
.text-senary {
	color: var(--senary) !important;
}

.text-white:focus,
.text-white:active,
.text-white {
	color: var(--white);
}

.text-danger:focus,
.text-danger:active,
.text-danger {
	color: var(--danger) !important;
}

.tooltip,
.popover {
	font-family: "Regular";
}

.tooltip-inner {
	font-size: 0.875em;
}

.hoverIcon,
.bgOverlay {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	/* background: rgba(0, 0, 0, 0.5); */
	z-index: 0;

	background: rgba(254, 254, 254, 0);
	background: -moz-linear-gradient(
		top,
		rgba(254, 254, 254, 0) 0%,
		rgba(0, 0, 0, 0.75) 100%
	);
	background: -webkit-gradient(
		left top,
		left bottom,
		color-stop(0%, rgba(254, 254, 254, 0)),
		color-stop(100%, rgba(0, 0, 0, 0.75))
	);
	background: -webkit-linear-gradient(
		top,
		rgba(254, 254, 254, 0) 0%,
		rgba(0, 0, 0, 0.75) 100%
	);
	background: -o-linear-gradient(
		top,
		rgba(254, 254, 254, 0) 0%,
		rgba(0, 0, 0, 0.75) 100%
	);
	background: -ms-linear-gradient(
		top,
		rgba(254, 254, 254, 0) 0%,
		rgba(0, 0, 0, 0.75) 100%
	);
	background: linear-gradient(
		to bottom,
		rgba(254, 254, 254, 0) 0%,
		rgba(0, 0, 0, 0.75) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#000000', GradientType=0 );
}

.bg-white {
	background-color: var(--white) !important;
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover,
.bg-dark {
	background-color: var(--dark) !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover,
.bg-primary {
	background-color: var(--primary) !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover,
.bg-secondary {
	background-color: var(--secondary) !important;
}

.bg-tertiary {
	background-color: var(--tertiary) !important;
}

.bg-quaternary {
	background-color: var(--quaternary) !important;
}

.bg-quinary {
	background-color: var(--quinary) !important;
}

.bg-senary {
	background-color: var(--senary) !important;
}

.bg-danger {
	background-color: var(--danger) !important;
}

.bg-gray {
	background-color: var(--light-gray) !important;
}
/* ---- preset style END ---- */

body {
	font-size: 14px;
	font-family: "Regular", Arial, Helvetica, sans-serif !important;
	line-height: 150%;
	overflow-x: hidden;
	color: var(--primary);
}

body.modal-open {
	overflow: hidden;
	height: 100%;
	position: relative;
}

.container {
	max-width: 1200px;
}

.main {
	overflow-x: hidden;
	width: 100%;
	padding-top: 90px;
}

body.withAnnouncement .main {
  padding-top: 135px;
}

@media (max-width: 991px) {
	body .main {
		padding-top: 90px !important;
	}
}

/*BUTTON OVERRIDES*/
.btn {
	-webkit-appearance: none;
	border-radius: 0;
	/* white-space: nowrap; */
	font-family: "Medium";
	text-transform: uppercase;
	/* color: var(--white) !important; */
	/* border: none; */
	padding-left: 1.5em;
	padding-right: 1.5em;
	font-size: 0.875em;
}

.btn-lg {
	font-size: 1em;
	height: 58px;
}
.btn-primary {
	background: var(--primary) !important;
	color: var(--white) !important;
	border: none !important;
}

.btn-secondary {
	background: var(--secondary) !important;
	color: var(--white) !important;
	border: none !important;
}

.btn-tertiary:focus,
.btn-tertiary:active,
.btn-tertiary {
	background: var(--tertiary) !important;
	color: var(--white) !important;
	border: none !important;
}

.btn-quaternary {
	background: var(--quaternary) !important;
	color: var(--white) !important;
	border: none !important;
}

.btn-quinary {
	background: var(--quinary) !important;
	color: var(--white) !important;
	border: none !important;
}
.btn-danger {
	background: var(--danger) !important;
	min-width: 110px;
	text-align: center;
	color: var(--white) !important;
	border: none !important;
}

.input-group-text {
	border-radius: 0;
}

.btn-tertiary:hover {
	background: var(--tertiary-hover) !important;
}

.btn-quaternary:hover {
	background: var(--quaternary-hover) !important ;
}

.btn-quinary:hover {
	background: var(--senary) !important ;
}
.btn-danger:hover {
	background: var(--danger-hover) !important ;
}

@media (max-width: 767px) {
	.flex-column .btn + .btn {
		margin-top: 1em;
	}

	.slick-dots {
		bottom: -10px;
	}
}
/*BUTTON OVERRIDES*/

/*GENERAL*/

.bg-cover,
.bgCover,
.bg-cover-absolute {
	position: relative;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
}

.bgCover {
	background-size: 100%;
}

.bgCover.flip {
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}

.bgCover.flip > div {
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}
.bg-cover-absolute {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

.rectangleBox {
	position: relative;
	width: 100%;
	overflow: hidden;
	padding-top: 80%;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.contentBlockItem:hover .grow,
.textOverImage:hover .grow,
.timelineItem:hover .grow,
.eventsItem:hover .grow,
.newsItem:hover .grow,
.rectangleBox:hover > .grow {
	-moz-transform: scale(1.04);
	-ms-transform: scale(1.04);
	-webkit-transform: scale(1.04);
	transform: scale(1.04);
}

.slick-slide .thumbItem {
	margin: 0;
}

/* before the alignment issue */

a.blockLink {
	overflow: hidden;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 2;
}

a.blockLink i {
	position: absolute;
	color: var(--white);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	z-index: 2;
}

.grow,
.timelineItem aside,
.slick-dots li button,
.btn,
.slick-slide,
.hoverIcon i,
a.blockLink i,
.videoItem::before,
a,
.badge {
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}

.hoverIcon {
	opacity: 0;
}

.hoverIcon:hover {
	opacity: 1;
}

@media (max-width: 575px) {
	.hoverIcon {
		opacity: 1;
	}
}

.hoverIcon i {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	color: var(--white);
}

.pt-10 {
	padding-top: 10%;
}
.pt-20 {
	padding-top: 20%;
}
.pt-30 {
	padding-top: 30%;
}
.pt-40 {
	padding-top: 40%;
}
.pt-50 {
	padding-top: 50%;
}
.pt-60 {
	padding-top: 60%;
}
.pt-70 {
	padding-top: 70%;
}
.pt-80 {
	padding-top: 80%;
}
.pt-90 {
	padding-top: 90%;
}
.pt-100 {
	padding-top: 100%;
}
.pt-110 {
	padding-top: 110%;
}
.pt-120 {
	padding-top: 120%;
}
.pt-130 {
	padding-top: 130%;
}
.pt-140 {
	padding-top: 140%;
}

.textOverImage {
	position: relative;
	margin-bottom: 2em;
	text-align: center;
}
.textOverImage aside {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.textOverImage aside h5,
.textOverImage aside h6 {
	font-size: 1.35em;
	text-transform: uppercase;
}

.sectionSeparator {
	border-bottom: 8px solid var(--danger);
}

/*SLOPE HEADERS*/
.headerBgImage {
	position: relative;
}
.headerBgImage:before {
	content: "";
	position: absolute;
	height: 150px;
	width: 100%;
	display: block;
	background-repeat: no-repeat;
	/* background-image: url("images/header-bg.png"); */
	background-position-y: center;
	top: 0;
	background-size: cover;
}
.redHeader,
.blueHeader {
	margin-top: 120px;
	position: relative;
}
.redHeader:before,
.blueHeader:before {
	content: attr(caption);
	height: 50px;
	width: auto;
	background-image: url("images/blue-slope.png");
	background-repeat: no-repeat;
	background-position: bottom right;
	position: absolute;
	left: 0;
	top: -50px;
	padding: 0 90px 0 20px;
	color: var(--white);
	font-size: 1.8em;
	line-height: 50px;
	font-family: "Bold";
}

.redHeader:before {
	background-image: url("images/red-slope.png");
}

@media only screen and (max-width: 575px) {
	.redHeader,
	.blueHeader {
		margin-top: 60px;
	}

	.redHeader:before,
	.blueHeader:before {
		height: 40px;
		padding-right: 60px;
		top: -40px;
		font-size: 1em;
		line-height: 40px;
	}
}
/*SLOPE HEADERS*/

.spring {
	position: relative;
}

@media (min-width: 767px) {
	.spring:after {
		position: absolute;
		content: "";
		background-position: center center;
		width: 40px;
		right: -20px;
		z-index: 1;
		top: 16px;
		bottom: 16px;
		background-image: url("images/spring.png");
		background-repeat: repeat-y;
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
	}
}

.ps__rail-y {
	z-index: 1;
	background: var(--gray) !important;
	width: 6px !important;
}

.ps__thumb-y {
	right: 0 !important;
	width: 6px !important;
	border-radius: 0 !important;
	background: var(--tertiary) !important;
}

@media only screen and (min-width: 768px) {
	.hvr-grow {
		display: inline-block;
		vertical-align: middle;
		-webkit-transform: perspective(1px) translateZ(0);
		transform: perspective(1px) translateZ(0);
		box-shadow: 0 0 1px rgba(0, 0, 0, 0);
		-webkit-transition-duration: 0.3s;
		transition-duration: 0.3s;
		-webkit-transition-property: transform;
		transition-property: transform;
	}

	.hvr-grow:hover,
	.hvr-grow:focus,
	.hvr-grow:active {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
		z-index: 1;
	}

	.hvr-grow:hover,
	.hvr-grow:focus,
	.hvr-grow:active {
		z-index: 2;
	}

	.hvr-glow {
		display: inline-block;
		vertical-align: middle;
		-webkit-transform: perspective(1px) translateZ(0);
		transform: perspective(1px) translateZ(0);
		box-shadow: 0 0 1px rgba(0, 0, 0, 0);
		-webkit-transition-duration: 0.3s;
		transition-duration: 0.3s;
		-webkit-transition-property: box-shadow;
		transition-property: box-shadow;
	}
	.hvr-glow:hover,
	.hvr-glow:focus,
	.hvr-glow:active {
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	}
}

.form-select {
	font-size: 1em;
}

.btn-light {
	background-color: #eeeeee;
}

.btn-light:hover {
	background-color: #dddddd;
}

.nav-tabs .nav-link.active {
	color: var(--white);
	background-color: var(--primary);
	border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .nav-link {
	margin-right: 5px;
	color: var(--dark-gray);
	background-color: var(--light-gray);
	font-family: "Medium";
	padding: 1rem 2rem;
	border-radius: 0;
	white-space: nowrap;
}

.nav-tabs.nav {
	overflow-y: hidden;
	overflow-x: auto;
}

.nav-link:focus,
.nav-link:hover {
	color: var(--dark-gray);
}

.quickLinksItem {
	display: block;
	padding-top: 12px;
	padding-bottom: 12px;
	color: var(--primary);
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.quickLinksItem:hover {
	color: var(--quinary);
}
.quickLinksItem + .quickLinksItem {
	border-top: 1px solid var(--gray);
}

.QuickLinks ul {
	list-style: none;
	padding: 0;
}

.QuickLinks ul li + li {
	border-top: 1px solid var(--gray);
}

/*QUICKLINKS SOLELY FOR SERVICES QUICKLINKS*/
.quickLinks {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: start;
}

.quickLinks li {
	flex: 1 1 500px;
	padding: 8px 0;
	border-bottom: 1px dotted burlywood;
}
.quickLinks li:before {
	content: "\e936";
	font-family: "uowd-icons" !important;
	margin-right: 10px;
}

input[type="radio"]:checked + label {
	background: var(--primary) !important;
	color: #ffffff;
}

.badge.rounded-pill {
	border: 1px solid var(--tertiary);
	color: var(--tertiary);
	cursor: pointer;
	font-size: 0.7rem;
	font-family: "Medium";
}

.badge.rounded-pill.light {
	border: 1px solid var(--gray);
	background-color: var(--light-gray);
	color: var(--dark);
	cursor: pointer;
	font-size: 0.7rem;
	font-family: "Medium";
}

input[type="radio"]:checked + label.light {
	background: var(--quaternary) !important;
	color: #ffffff;
}

.badge.rounded-pill:hover {
	background: var(--bs-gray);
	color: #ffffff;
}

fieldset {
	position: relative;
	padding: 20px 20px 10px;
}

legend {
	position: absolute;
	top: -10px;
	left: 20px;
	font-size: 0.75rem;
	padding: 0 10px;
	font-family: Medium;
	background-color: var(--danger-hover);
	color: var(--white);
}
